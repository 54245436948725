<app-sidebar (onToggleSideNav)="onToggleSideNav($event)" [collapsedInput]="isSideNavCollapsed" ></app-sidebar>
<app-main-body [collapsed]="isSideNavCollapsed" [screenWidth]="screenWidth" (onToggleSideNav)="onToggleSideNav($event)">
  <div class="body">
    <!-- <app-admin-header (onToggleSideNav)="onToggleSideNav($event)"></app-admin-header> -->
    <section class="content">
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </section>
  </div>
</app-main-body>

