import { Component, Input } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AdminHeaderComponent } from '../../admin-header/admin-header.component';
import { SidebarModule } from '../../sidebar/sidebar.module';
import { MainBodyComponent } from '../../main-body/main-body.component';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [RouterOutlet, AdminHeaderComponent, SidebarModule, MainBodyComponent],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent {
  @Input() collapsed = false;
  @Input() screenWidth = 0;

  title = 'sidenav';

  isSideNavCollapsed!:boolean;


  onToggleSideNav(data: SideNavToggle): void {

    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }

  constructor() { }

  ngOnInit(): void {
  }
}
