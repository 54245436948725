import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
      providedIn: 'root'
})
export class EncrDecrService {
    public  _key = "kalyansrimaNTA";
    public  encryptionKey!: string;
    constructor() {console.log("inject") }


      VVS_VOIS_ENCRYPT(req: any) {
            this.encryptionKey = this._key;
            let salt = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg==');
            let iterations = 1000;
            let keyAndIv = CryptoJS.PBKDF2(this.encryptionKey, salt, {
                  keySize: 256 / 32 + 128 / 32,
                  iterations: iterations,
                  hasher: CryptoJS.algo.SHA1
            });

            let hexKeyAndIv = CryptoJS.enc.Hex.stringify(keyAndIv);
            let key = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(0, 64));
            let iv = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(64, hexKeyAndIv.length));
            let encryptedStr = CryptoJS.AES.encrypt(JSON.stringify(req), key, { iv: iv }).toString();

            return encryptedStr;
      }


      VVS_VOIS_DECRYPT(res: any) {
            this.encryptionKey = this._key;
            let salt = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg==');
            let iterations = 1000;
            let keyAndIv = CryptoJS.PBKDF2(this.encryptionKey, salt, {
                  keySize: 256 / 32 + 128 / 32,
                  iterations: iterations,
                  hasher: CryptoJS.algo.SHA1
            });
            let hexKeyAndIv = CryptoJS.enc.Hex.stringify(keyAndIv);
            let key = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(0, 64));
            let iv = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(64, hexKeyAndIv.length));
            let decryptedStr = CryptoJS.AES.decrypt(res, key, { iv: iv });
            const decryptedData = JSON.parse(decryptedStr.toString(CryptoJS.enc.Utf8));
            console.log(decryptedData);
            return decryptedData;
      }
}
