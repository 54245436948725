import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AdminHeaderComponent } from '../../layout/admin-header/admin-header.component';
import { CommonModule } from '@angular/common';
interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}
@Component({
  selector: 'app-main-body',
  standalone: true,
  imports: [RouterOutlet, AdminHeaderComponent, CommonModule],
  templateUrl: './main-body.component.html',
  styleUrl: './main-body.component.scss'
})

export class MainBodyComponent implements OnInit {

  @Input() collapsed = true;
  @Input() screenWidth = 0;
  @Output() onToggleSideNav: EventEmitter<SideNavToggle> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {

  }

  getBodyClass(): string {
    let styleClass = '';
    if(this.collapsed && this.screenWidth > 768) {
      styleClass = 'body-trimmed';
    } else if(this.collapsed && this.screenWidth <= 768) {
      styleClass = 'body-md-screen'
    } else if(this.screenWidth == 0) {
      styleClass = 'body-trimmed';
    }
    return styleClass;
  }

  toggleCollapse(event:SideNavToggle): void {
    this.onToggleSideNav.emit(event);
  }
}
