<div class="login-bg">
  <div class="login-strip">
    <div class="login-strip-ls">
      <img src="assets/images/bureau-veritas.png" alt="">
    </div>
    <div class="login-strip-rs">Work Safety Solution</div>
  </div>
  <div class="container-fluid p-0">

    <div class="row g-0 align-items-center pt-4">
      <div class="col-lg-8">
        <div class="auth-bg">
          <div class="d-flex min-vh-70 p-4 pb-2">
            <!-- <div class="bg-overlay">

            </div> -->
            <div class="content-section">

              <div class="row g-0">

                <div class="col-lg-6">


                  <div class="logo-container">
                    <div class="logo-left">
                      <div>
                        <span class="vois-name">_<span class="lp-n">VO<b>IS</b></span> | SHW</span>
                      </div>
                    </div>
                    <div class="logo-right">
                      <!-- <h2>_VOIS | HSW</h2> -->
                      <!-- <div class="logo-as"><img src="assets/images/proxy-image.png" alt="Ashok Stambh"></div>
              <div class="logo-a75"><img src="assets/images/azadi_75.png" alt="azadi 75"></div> -->
                    </div>
                  </div>
                  <div class="row g-0">
                    <div class="col-lg-12">
                      <p class="login-text">
                        We're the
                        strategic arm of Vodafone Group Plc, creating value and enhancing quality for our partners and
                        customers, while operating from 8 locations: Albania, Egypt, Hungary, India, Romania, Spain,
                        Turkey, and the UK.
                      </p>
                    </div>
                  </div>

                </div>
                <div class="col-lg-6">
                  <!-- <img src="assets/images/login-bg-5.png" alt="azadi 75"> -->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div class="footer">
      <span>Managed by Bureau Veritas
        <!-- &nbsp;|&nbsp; <a href="javascript:void(0)" [matMenuTriggerFor]="menu">Privacy Policy</a>
        <mat-menu #menu="matMenu" class="mat-menu-custom-footer">
          <button mat-menu-item (click)="openLink('https:
          <button mat-menu-item (click)="openLink('https:
        </mat-menu> -->
      </span>
    </div>
  </div>
</div>
