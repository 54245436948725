import { inject } from '@angular/core';
import {HttpRequest,HttpEvent,HttpResponse, HttpHandlerFn, HttpInterceptorFn} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EncrDecrService } from '../service/encr-decr.service';


export const DncryptionInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const encryptionService = inject(EncrDecrService);
    const cloned = req.clone()
    return next(cloned).pipe(
      map((response:any) => {
        if (response instanceof HttpResponse && response.body.type != "application/pdf"  && response.body.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          const decryptedData = encryptionService.VVS_VOIS_DECRYPT(
            response.body.X_KEY
          );
          return response.clone({
            body: decryptedData,
          });
        }
        return response;
      })
    );
};
