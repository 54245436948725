<div class="top-header">
  <div class="header-ls">
    <ul class="header-ul-ls">
      <!-- <li class="menu-tgl">
        <span class="material-symbols-outlined tgl-btn" (click)="toggleCollapse()">
          grid_view
        </span>
      </li> -->
      <li class="menu-tgl">
        <span class="material-symbols-outlined tgl-btn" (click)="goBack()">
          arrow_back_ios_new
          </span>
      </li>
      <li class="page-name" [innerHTML]="vvs_vois_PageTitle"></li>
    </ul>
  </div>
  <div class="header-rs">
    <ul class="header-ul-rs">
      <li>
        <select class="location-dd" (change)="VVS_VOIS_ON_SITE_CHANGE($event)">
          @for (option of VVS_VOIS_SITE_LIST ; track option.KEY) {
            <option>{{ option.VALUE }}</option>
            }

        </select>
      </li>
      <li>
        <span class="vois-user-name">{{VVS_VOIS_PTW_USER_NAME}}</span>
      </li>
      <li class="menu-user">
        <a href="javascript:void(0);" [matMenuTriggerFor]="menu">
          <span class="material-symbols-outlined">
            settings
          </span>
        </a>
        <mat-menu #menu="matMenu" class="mat-menu-custom">
          <button mat-menu-item (click)="logoutSec()">
            <span class="material-symbols-outlined">logout</span>
            <span>Logout</span>
          </button>
        </mat-menu>
      </li>
    </ul>
  </div>
</div>
