import { inject } from '@angular/core';
import { HttpRequest, HttpHandlerFn, HttpEvent, HttpInterceptorFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export const LoaderInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    const loaderService = inject(NgxUiLoaderService);
    const cloned = req.clone();
    loaderService.start('master');
    return next(cloned).pipe(
        finalize(() => {
            loaderService.stop('master');
        })
    );
};
