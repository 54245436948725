import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginRequirement, LoginResponse, LogoutReq } from '../interfaces/login';
import { map } from "rxjs/operators";
import { VVS_VOIS_API_LOGIN, } from '../_common/constant';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public VVS_VOIS_IS_LOGIN_SUBJECT = new BehaviorSubject<boolean>(this.VVS_VOIS_HAS_TOKEN());
  private VVS_VOIS_CURRENT_USER_SUBJECT: BehaviorSubject<LoginResponse>;
  public VVS_VOIS_CURRENT_USER: Observable<LoginResponse>;

  constructor(private _http: HttpClient, private _router: Router) {
    this.VVS_VOIS_CURRENT_USER_SUBJECT = new BehaviorSubject<LoginResponse>(
      JSON.parse(localStorage.getItem("currentUser")!)
    );
    this.VVS_VOIS_CURRENT_USER = this.VVS_VOIS_CURRENT_USER_SUBJECT.asObservable();
  }

  VVS_VOIS_LOGIN(loginData: LoginRequirement) {
    const params: LoginRequirement = {
      EMAIL: loginData.EMAIL,
      PASSWORD: loginData.PASSWORD,
    };
    return this._http.post<any>(VVS_VOIS_API_LOGIN, params).pipe(
      map((user: LoginResponse) => {
        console.log(user)

        if (user && user.Response.token) {

          this._router.navigate(['/admin/dashboard']);

          this.VVS_VOIS_IS_LOGIN_SUBJECT.next(true);

          this.VVS_VOIS_CURRENT_USER_SUBJECT.next(user);

          localStorage.clear();
          localStorage.setItem("currentUser", JSON.stringify(user));

          return user;
        } else {

          return user;
        }
      })
    );

  }

  VVS_VOIS_SSO_LOGIN(loginData: any) {

    const params: any = {
      TOKEN: loginData.TOKEN,
    };



    return this._http.post<any>(VVS_VOIS_API_LOGIN, params).pipe(
      map((user: LoginResponse) => {

        console.log(user)

        if (user && user.Response.token) {

          this._router.navigate(['/admin/dashboard']);

          this.VVS_VOIS_IS_LOGIN_SUBJECT.next(true);

          this.VVS_VOIS_CURRENT_USER_SUBJECT.next(user);

          localStorage.clear();
          localStorage.setItem("currentUser", JSON.stringify(user));

          return user;
        } else {

          return user;
        }
      })
    );

  }

  public get VVS_VOIS_CURRENT_USER_VALUE(): LoginResponse {

    return this.VVS_VOIS_CURRENT_USER_SUBJECT.value;
  }


  private VVS_VOIS_HAS_TOKEN(): boolean {


    return !!localStorage.getItem("currentUser");
  }


  public get VVS_VOIS_IS_LOGGEDIN(): Observable<boolean> {
    return this.VVS_VOIS_IS_LOGIN_SUBJECT.asObservable();
  }
  VVS_VOIS_LOGOUT() {
    localStorage.clear();
    this.VVS_VOIS_CURRENT_USER_SUBJECT.next(null as any);
  }



}
