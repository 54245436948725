import { DatePipe } from "@angular/common";
import { AbstractControl, FormGroup } from "@angular/forms";
import { VVS_VOIS_MASTER_LISTING } from "../interfaces/ptw-master";
import moment from 'moment';


export class Helper {

  VVS_VOIS_DATE_FORMAT(dateValue: string | Date = '', format = 'dd/MM/yyyy') {
    if (dateValue === '') { return dateValue; }
    const datePipe = new DatePipe('en-US');
    dateValue = datePipe.transform(dateValue, format)!;
    return dateValue;
  }

  VVS_VOIS_DATE_FORMAT_FOR_PTW_ADD(dateValue: string | Date = '', format = 'yyyy-MM-dd HH:mm:ss') {
    if (dateValue === '') { return dateValue; }
    const datePipe = new DatePipe('en-US');
    dateValue = datePipe.transform(dateValue, format)!;
    return dateValue;
  }
  VVS_VOIS_DATE_FORMAT_FOR_HIRA_ADD(dateValue: string | Date = '', format = 'yyyy-MM-dd HH:mm:ss') {
    if (dateValue === '') { return dateValue; }
    const datePipe = new DatePipe('en-US');
    dateValue = datePipe.transform(dateValue, format)!;
    return dateValue;
  }

  VVS_VOIS_DATE_FORMAT_ACTIVITY(dateValue: string | Date = '', format = 'yyyy-MM-dd') {
    if (dateValue === '') { return dateValue; }
    const datePipe = new DatePipe('en-US');
    dateValue = datePipe.transform(dateValue, format)!;
    return dateValue;
  }


  VVS_VOIS_CONVERT_DATE_STRING_TO_DATE(dateString: String) {
    if (dateString == null || dateString == "") {
      return null
    }
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    const parsedDate = new Date(year, month - 1, day);
    return parsedDate;
  }

  handleFromDataValue(params: any) {
    const result: any = {};
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const userInput = params[key];
        if (typeof userInput === 'object') {
          if (userInput instanceof Date) {
            result[key] = this.VVS_VOIS_DATE_FORMAT(userInput);
          }
          else if (userInput?.hasOwnProperty('key'))
            result[key] = userInput.key;
        }
        else
          result[key] = userInput;
      }
    }
    return result;
  }




  public VVS_VOIS_INPUT_FILTER_FOR_AUTOCOMPLETE(VALUE: any, inputJson: any) {
    const normalizedValue = String(VALUE).toLowerCase();
    return inputJson.filter((Option: any) => {
      const optionValue = String(Option.VALUE).toLowerCase();
      return optionValue.includes(normalizedValue);
    });
  }





  VVS_VOIS_SET_AUTOCOMPLETE_IF_STRING(orgArray: VVS_VOIS_MASTER_LISTING[], editData: any, formControl: AbstractControl) {
    console.log(orgArray)
    console.log(editData)
    for (const element of orgArray) {
      if (element.KEY == editData.VALUE) {
        formControl.setValue(element)
      }
    }
  }

  VVS_VOIS_COMPARE_WITH_SERVICE_VALUES(formGroup: FormGroup, serviceValues: any): string[] {
    const modifiedFields: string[] = [];
    for (const controlName of Object.keys(serviceValues)) {
      const control = formGroup.get(controlName);

      if (control && !control.pristine) {
        if (control.value !== serviceValues[controlName]) {

          modifiedFields.push(controlName);
        }
      }
    }

    return modifiedFields;
  }
}
