import { ApplicationConfig, LOCALE_ID, Provider } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { provideHttpClient, withInterceptors, } from '@angular/common/http';
import { JwtInterceptor } from './_core/interceptor/jwt.interceptor';
import { ErrorInterceptor } from './_core/interceptor/error.interceptor';
import { EncryptionInterceptor } from './_core/interceptor/encryption.interceptor';
import { DncryptionInterceptor } from './_core/interceptor/decryption.interceptor';
import { fakeBackendProvider } from './_core/interceptor/fake-backend';
import { EncrDecrService } from './_core/service/encr-decr.service';
import { Helper } from './_core/_common/default';
import { LoaderInterceptor } from './_core/interceptor/loader.interceptor'
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
export function provideLocaleConfig(): Provider[] {
  return [
    { provide: LOCALE_ID, useValue: 'en-GB', },
    fakeBackendProvider, EncrDecrService,Helper,
    { provide: DateAdapter, useClass: NativeDateAdapter, deps: [MAT_DATE_LOCALE], },
    { provide : LocationStrategy, useClass : HashLocationStrategy }
  ];
}

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideClientHydration(), provideAnimationsAsync(),
  provideNativeDateAdapter(), provideLocaleConfig(),
  provideHttpClient(
    withInterceptors([JwtInterceptor,ErrorInterceptor, EncryptionInterceptor, DncryptionInterceptor,LoaderInterceptor])
  ),]
};
