import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { SublevelMenuComponent } from './sublevel-menu.component';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    SidebarComponent,
    SublevelMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule
  ],
  exports: [SidebarComponent]
})
export class SidebarModule { }
