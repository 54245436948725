import { inject } from '@angular/core';
import { HttpRequest, HttpEvent, HttpHandlerFn, HttpInterceptorFn } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EncrDecrService } from '../service/encr-decr.service';


export const EncryptionInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const encryptionService = inject(EncrDecrService);
  const request = req.clone()
  if (request.method === 'POST' || request.method === 'PUT' || request.method === 'GET') {
    console.log(request.body)
    if(!(request.body instanceof FormData)){
      const encryptedData = encryptionService.VVS_VOIS_ENCRYPT(request.body);
      const encryptedRequest = request.clone({
        body: {
          X_KEY: encryptedData
        },
      });
      return next(encryptedRequest);
    }
   return next(request)
  } else {
    return next(request)
  }
};
