import { HttpRequest, HttpEvent, HttpInterceptorFn, HttpHandlerFn, } from '@angular/common/http';
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from '../service/auth.service';



export const JwtInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const authenticationService = inject(AuthService);
  let VVS_VOIS_CURRENT_USER = authenticationService.VVS_VOIS_CURRENT_USER_VALUE;
  if (VVS_VOIS_CURRENT_USER?.Response?.token) {
    const cloned = req.clone({
      setHeaders: {
        Authorization: `${VVS_VOIS_CURRENT_USER?.Response?.token}`,
      },
    });
    return next(cloned);
  } else {
    return next(req);
  }
};
