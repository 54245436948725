import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareHeaderPageTitleDataService {

  public selectedPageTitle = new BehaviorSubject<string>('');
  selectedPageTitle$ = this.selectedPageTitle.asObservable();

  set setSelectedPageTitle(newValue: string) {
    this.selectedPageTitle.next(newValue);
  };

}
